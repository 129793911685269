export interface LauncherData { state: LauncherState; progress?: number; }

export enum LauncherState {
    ERROR = "error",
    READY = "ready",
    CHECK_JAVA = "check_java",
    CHECK_INSTALL = "check_install",
    INSTALL = "install",
    CONFIGURE = "configure",
    START = "start",
    PLAY = "play"
}

export enum SignalErrorCodes {
    CONFLICT = 4090,
    NOT_FOUND = 4040,
    UNAUTHORIZED = 4030,
}

export interface Profile { accessToken: string; name: string; id: string; skins: { id: string; state: string; url: string; }[]; }

export interface MessageData {
    profile: Omit<Profile, "accessToken">; launcher: LauncherData;
}

export enum Command {
    ACCEPT = "accept",
    LAUNCH = "launch"
}

export interface AcceptCommand { command: Command.ACCEPT; accessToken: string; }
export interface LaunchCommand { command: Command.LAUNCH; }

export type CommandData = AcceptCommand | LaunchCommand;
