import { useEffect, useState } from 'react';
import { API_URL } from "../../config";
import { getActiveSkin } from "../../hooks/useSignal";
import type { Profile } from "../../launcher";
import { Button } from '../Button/Button';
import { Form, Inputs } from "../Form/Form";

export function ChangeSkinForm({ profile }: { profile: Profile }) {
  const [isLoading, setLoading] = useState(false);
  const activeSkinId = getActiveSkin(profile.skins);
  useEffect(() => { setLoading(false); }, [activeSkinId])
  return <Inputs>
    <label htmlFor="skin">
      Skin
    </label>
    <Form input disabled={isLoading} onSubmit={event => {
      setLoading(true);
      fetch(`${API_URL}/minecraftservices/minecraft/profile/skins`, {
        method: "POST",
        body: new FormData(event.currentTarget),
        headers: { 'Authorization': `Bearer ${profile.accessToken}` }
      }).catch(console.error);
    }}>
      <input
        type="file"
        name="skin"
        id="skin"
        accept="png,image/png"
        required
        disabled={isLoading}
        onInput={({ currentTarget }) => {
          const { files } = currentTarget;
          if (!files || files.length === 0) return;
          if (files[0].size > 16000) {
            currentTarget.setCustomValidity("Skin must be under 16kB");
            currentTarget.reportValidity();
          } else if (currentTarget.validity.customError) {
            currentTarget.setCustomValidity('');
            currentTarget.reportValidity();
          }
        }}
      />
      <select name="variant">
        <option value="classic">Classic</option>
        <option value="slim">Slim</option>
      </select>
      <Button disabled={isLoading} type="submit" link>Change</Button>
    </Form>
  </Inputs>;
}
