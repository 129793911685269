import { createContext, Dispatch, PropsWithChildren, SetStateAction, useCallback, useContext, useState } from "react";
import { SIGNAL_URL } from "../config";
import { Profile, MessageData, CommandData, Command, LauncherData, SignalErrorCodes } from "../launcher";

export interface ConnectedSignalState {
    profile: Profile;
    socket: WebSocket;
    sendCommand(data: CommandData): void;
    launcher: LauncherData;
}

export enum PendingSignalState {
    CONNECTING = "connecting",
    CONFLICT = "conflict",
    NOT_FOUND = "not_found",
    UNAUTHORIZED = "unauthorized",
    ERROR = "error",
    IDLE = "idle"
}

export const SIGNAL_ERROR_CODES = {
    [SignalErrorCodes.CONFLICT]: PendingSignalState.CONFLICT,
    [SignalErrorCodes.NOT_FOUND]: PendingSignalState.NOT_FOUND,
    [SignalErrorCodes.UNAUTHORIZED]: PendingSignalState.UNAUTHORIZED,
} as const;

type ValueOf<T extends Record<string, unknown>> = T[keyof T];
export const isSignalError = (signal: PendingSignalState): signal is ValueOf<typeof SIGNAL_ERROR_CODES> => Object.values(SIGNAL_ERROR_CODES).includes(signal as ValueOf<typeof SIGNAL_ERROR_CODES>);
const isConnectedState = (state: SignalState): state is ConnectedSignalState => typeof state !== "string";
export const isConnectedSignal = (signal: Signal): signal is ConnectedSignal => isConnectedState(signal.state);

type SignalState = ConnectedSignalState | PendingSignalState;

const SignalContext = createContext<[SignalState, Dispatch<SetStateAction<SignalState>>] | null>(null);

export function SignalProvider({ children }: PropsWithChildren<{}>) {
    const state = useState<SignalState>(PendingSignalState.IDLE);
    return <SignalContext.Provider value={state}>
        {children}
    </SignalContext.Provider>;
}

export const getActiveSkin = (skins: Profile["skins"]) => skins.find(({ state }) => state === "ACTIVE");

export interface ConnectedSignal {
    state: ConnectedSignalState;
    stop(): void;
    launch(): void;
}

export interface IdleSignal {
    state: PendingSignalState;
    establish?(signalId: string, accessToken: string): Promise<SignalState>;
}

export type Signal = ConnectedSignal | IdleSignal;

export function useSignal(): Signal {
    const context = useContext(SignalContext);
    if (!context) throw new Error("useSignal outside SignalProvider");
    const [state, setState] = context;
    const canConnect = state === PendingSignalState.IDLE
        || state === PendingSignalState.UNAUTHORIZED
        || state === PendingSignalState.CONFLICT;
    const establish = useCallback((signalId: string, accessToken: string) =>
        new Promise<SignalState>((resolve, reject) => {
            setState(PendingSignalState.CONNECTING);
            const socket = new WebSocket(`${SIGNAL_URL}?signal=${signalId}`);
            const sendCommand = (data: CommandData) => socket.send(JSON.stringify(data));
            socket.addEventListener("open", () => {
                sendCommand({ command: Command.ACCEPT, accessToken });
            });
            socket.addEventListener("close", ({ code }) => {
                if (code in SIGNAL_ERROR_CODES) {
                    const eventState = SIGNAL_ERROR_CODES[code as keyof typeof SIGNAL_ERROR_CODES];
                    setState(eventState);
                    reject(eventState);
                }
                setState(PendingSignalState.IDLE);
            });
            socket.addEventListener("message", (event) => {
                try {
                    const { profile, launcher }: MessageData = JSON.parse(event.data);
                    const eventState = { socket, sendCommand, profile: { ...profile, accessToken }, launcher };
                    setState(eventState);
                    resolve(eventState);
                } catch (error) {
                    console.error("Message error", error);
                }
            });
            socket.addEventListener("error", (event) => {
                console.error("Websocket error", event);
            });
        }), [setState]);
    return isConnectedState(state)
        ? {
            state,
            stop: () => { state.socket.close() },
            launch: () => { state.sendCommand({ command: Command.LAUNCH }) }
        }
        : { state, establish: canConnect ? establish : undefined };
}
