import './App.css';
import { lazy } from 'react';
import { LoginForm } from './components/forms/LoginForm';
import { PlayForm } from './components/forms/PlayForm';
import { getActiveSkin, isConnectedSignal, useSignal } from './hooks/useSignal';

const Skin = lazy(() => import('./components/Skin/Skin'));

function App() {
  const signal = useSignal();
  const signalId = window.location.hash.substring(1);
  const skin = isConnectedSignal(signal) ? getActiveSkin(signal.state.profile.skins) : undefined;

  return (<>
    <div className="app">
      <header>
        <img src="logo.png" alt="Logo" className="logo" />
      </header>
      {typeof signalId === "string" && signalId.length > 0 &&
        <footer>
          {isConnectedSignal(signal)
            ? <PlayForm signal={signal} />
            : <LoginForm {...{ signal, signalId }} />}
        </footer>}
    </div>
    {skin && <Skin skinUrl={skin.url} />}
  </>);
}

export default App;
