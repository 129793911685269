import { useState } from 'react';
import { Button } from '../Button/Button';
import { IdleSignal, isSignalError, PendingSignalState } from "../../hooks/useSignal";
import { Form } from '../Form/Form';

function setCustomValidity(form: HTMLFormElement, name: string, error: string) {
  const element = form.elements.namedItem(name);
  if (element && element instanceof HTMLInputElement) {
    element.setCustomValidity(error);
    element.reportValidity();
  }
}

export function LoginForm({ signal: { establish }, signalId }: { signal: IdleSignal, signalId: string }) {
  const [onHold, setOnHold] = useState(false);
  const isDisabled = onHold || !establish;

  return <Form disabled={isDisabled} onSubmit={({ currentTarget }) => {
    const data = new FormData(currentTarget);
    establish?.(data.get("signal-id") as string, data.get("access-token") as string)
      .catch((error) => {
        if (!isSignalError(error)) return;
        console.error({ error });
        if (error === PendingSignalState.NOT_FOUND)
          setCustomValidity(currentTarget, "signal-id", "No launcher listening to this signal");
        else if (error === PendingSignalState.UNAUTHORIZED)
          setCustomValidity(currentTarget, "access-token", "Invalid credentials");
        else if (error === PendingSignalState.CONFLICT) {
          setOnHold(true);
          setTimeout(() => { setOnHold(false); }, 10000);
          setCustomValidity(currentTarget, "signal-id", "Signal already established");
        }
      });
  }}>
    <header>
      <label>Signal</label>
    </header>

    <div className="inputs">
      <input
        name="signal-id"
        id="signal-id"
        required
        minLength={1}
        type="text"
        defaultValue={signalId}
      />
      <label>
        Access Token
        <input
          name="access-token"
          required
          minLength={1}
          type="password"
          autoFocus
          onInput={({ currentTarget }) => {
            if (currentTarget.validity.customError) {
              currentTarget.setCustomValidity('');
              currentTarget.reportValidity();
            }
          }}
        />
      </label>
    </div>

    <footer>
      <Button disabled={isDisabled}>Establish signal</Button>
    </footer>
  </Form>;
}
