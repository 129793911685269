import { PropsWithChildren } from "react";
import "./Button.css";

interface Props {
    onClick?(): void;
    disabled?: boolean;
    link?: boolean;
    type?: "submit" | "button"
}

export function Button({ children, onClick, disabled, link, type = link ? "button" : undefined }: PropsWithChildren<Props>) {
    const className = link ? 'button-link' : 'button';
    return <button
        disabled={disabled}
        type={type}
        onClick={!disabled ? onClick : undefined}
        className={className}>
        {children}
    </button>
}

export function LinkButton({ link, children, ...props }: PropsWithChildren<{ href: string, link?: boolean, download?: boolean | string }>) {
    const className = link ? 'button-link' : 'button';
    return <a role="button" className={className} {...props}>{children}</a>;
}