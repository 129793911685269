import "./Form.css";
import { FormEventHandler, PropsWithChildren } from "react";

interface Props { onSubmit?: FormEventHandler<HTMLFormElement>; disabled?: boolean; input?: boolean };

export function Form({ onSubmit, disabled, children, input }: PropsWithChildren<Props>) {
    return <form className={input ? "input" : "form"} onSubmit={(event) => {
        event.preventDefault();
        if (!disabled) onSubmit?.(event);
    }}>
        {children}
    </form>;
}

export function FormDiv({ children }: PropsWithChildren<{}>) {
    return <div className="form">{children}</div>;
}

export function Inputs({ children }: PropsWithChildren<{}>) {
    return <div className="inputs">{children}</div>;
}