import { Button } from '../Button/Button';
import { StatusBar } from '../StatusBar/StatusBar';
import { ChangeSkinForm } from './ChangeSkinForm';
import { ConnectedSignal } from "../../hooks/useSignal";
import { LauncherState } from "../../launcher";
import { FormDiv } from "../Form/Form";

export function PlayForm({ signal }: { signal: ConnectedSignal }) {
  const { state, stop, launch } = signal;
  const { profile, launcher } = state;

  const STATIC_TEXT: Record<Exclude<LauncherState, "install">, string> = {
    [LauncherState.READY]: "Ready to play",
    [LauncherState.ERROR]: "Something went wrong",
    [LauncherState.CHECK_JAVA]: "Checking local Java installation...",
    [LauncherState.CHECK_INSTALL]: "Checking local installation...",
    [LauncherState.CONFIGURE]: "Configuring...",
    [LauncherState.START]: "Starting...",
    [LauncherState.PLAY]: "Playing!"
  }

  const statusText = launcher.state === LauncherState.INSTALL
    ? `Installing... ${launcher.progress}%`
    : STATIC_TEXT[launcher.state];

  return <>
    <StatusBar>{statusText}</StatusBar>
    <FormDiv>
      <header>
        {profile.name}
      </header>

      <ChangeSkinForm profile={profile} />

      <footer>
        <Button disabled={launcher.state !== LauncherState.READY} onClick={() => launch()}>Play</Button>
      </footer>
      <Button link onClick={() => stop()}>Logout</Button>
    </FormDiv>
  </>;
}
